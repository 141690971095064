import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import {
	ClipboardCheck,
	Pen,
	Phone,
	EmojiLaughing,
} from 'react-bootstrap-icons';

import HeroCell from '../images/framework/cell.svg';

import CtaLink from '../components/framework/cta-link';
import Prices from '../components/framework/prices';

import SaliveTest from '../components/framework/salive-test';
import ThroatTest from '../components/framework/throat-test';
import DeliveryTime from '../components/framework/delivery-time';
import Location from '../components/framework/location';

import TravelInfo from '../components/framework/travel-info';
import Framework from '../components/framework';
import Seo from '../components/framework/seo';

const ServiceItem = ({ data, location }) => {
	const { t } = useTranslation('template_service');
	const service = data.service;
	const product = data.service.product;

	return (
		<Framework location={location}>
			<Seo
				description={t('meta_description', {
					service_name: data.service.name,
				})}
				tags={t('meta_keywords', {
					service_name: data.service.name,
				})}
				title={t('title', {
					service_name: data.service.name,
				})}
			/>

			<section id="intro">
				<div className="container">
					<img alt="" className="hero-cell" src={HeroCell} />
					<div className="row my-5 pt-5 justify-content-start align-items-center">
						<div className="">
							<div className="row"></div>

							<h1 className="h1">{data.service.name}</h1>
							<p className="text-secondary">{t('t1')}</p>
							<CtaLink label={t('t2')} linkUrl="/book" />
						</div>
					</div>
				</div>
			</section>
			<section id="specification">
				<div className="container mb-3">
					<div className="p-3 bg-light rounded">
						<h3 className="mb-3 h5">{t('t3')}</h3>
						<p>{product.certification}</p>
					</div>
				</div>
			</section>
			<section id="checks">
				<div className="container my-4">
					<div className="row">
						<div className="col-6 col-md-3 text-center mb-3">
							<div className="mb-2 h3">
								<ClipboardCheck />
							</div>
							<div className="">{t('t4')}</div>
						</div>
						<div className="col-6 col-md-3 text-center mb-3">
							<div className="mb-2 h3">
								<Phone />
							</div>
							<div className="">
								{t('t5', {
									delivery_time: t(`${service.translation_key}_delivery_time`),
								})}
							</div>
						</div>
						<div className="col-6 col-md-3 text-center">
							<div className="mb-2 h3">
								<Pen />
							</div>
							<div className="">{t('t6')}</div>
						</div>
						<div className="col-6 col-md-3 text-center">
							<div className="mb-2 h3">
								<EmojiLaughing />
							</div>
							<div className="">{t('t7')}</div>
						</div>
					</div>
				</div>
			</section>
			<section id="prices">
				<div className="container my-3 my-lg-3 py-lg-3">
					<Prices types={[product.type]} />
				</div>
			</section>

			{product.type === 'antigen' && (
				<div className="my-lg-5 my-3">
					<SaliveTest />
				</div>
			)}
			{product.type === 'pcr' && (
				<div className="my-lg-5 my-3">
					<ThroatTest />
				</div>
			)}

			<section id="delivery-time">
				<div className="container-fluid bg-primary-gradient2 py-5 mb-lg-5">
					<div className="row">
						<div className="col-md-6">
							<DeliveryTime />
						</div>
					</div>
				</div>
			</section>

			<section id="info">
				<div className="container mt-lg-5 pt-5">
					<TravelInfo />
				</div>
			</section>
			<section id="location">
				<div className="container-fluid bg-light px-0 mt-5 py-5 ">
					<div className="container">
						<h2 className="mb-3 h4">{t('t8')}</h2>
						<Location types={[product.type]} />
					</div>
				</div>
			</section>
		</Framework>
	);
};

ServiceItem.propTypes = {
	data: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

export default ServiceItem;

export const query = graphql`
	query ServiceBySlug($slug: String!) {
		locales: allTranslation(filter: { id: { ne: "dummy" } }) {
			...translationFields
		}

		service(slug: { eq: $slug }) {
			currency {
				code
			}
			name
			partner {
				id
			}
			price_from
			product {
				certification
				type
			}
			translation_key
		}
	}
`;
